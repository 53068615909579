import axios, { AxiosError } from 'axios';

axios.interceptors.request.use(
   (config) => {
      const accessToken = localStorage.getItem('access-token');
      config.headers.authorization = `Bearer ${accessToken}`;
      return config;
   },
   (error) => {
      return Promise.reject(error);
   },
);

const fetcherInstance = axios.create({
   baseURL: 'https://app.titl.lol/',
   // .. other options
});

export const get = fetcherInstance.get;
export const post = fetcherInstance.post;

export const patch = fetcherInstance.patch;

export const put = fetcherInstance.put;

export const remove = fetcherInstance.delete;

export const fetcher = async (url: string) => {
   try {
      const { data } = await get(url, { withCredentials: true });

      return data;
   } catch (err) {
      const error = err as AxiosError;
      throw error.response;
   }
};
